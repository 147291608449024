import React from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';
import { BigBanner } from '../components/adsense';
// コードハイライト
import 'prismjs/themes/prism-okaidia.css';
// 行番号
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
// ショートコード
import { MDXProvider } from '@mdx-js/react';
import { CodePen } from '../assets/js/code-pen';

const BlogPostTemplate = props => {
  // ショートコード
  const shortcodes = { CodePen };

  // タイトル等
  const siteName = props.data.site.siteMetadata.title;
  const { siteUrl, twitterID } = props.data.site.siteMetadata;
  let blogData = props.data.mdx;

  // 各データ
  let {
    title,
    description,
    date,
    category,
    tags,
    heroImage
  } = blogData.frontmatter;

  // htmlのページタイトル
  let htmlTitle = title ? `${title} | ${siteName}` : siteName;

  // url
  let nowUrl = props.location.href;

  return (
    <Layout
      title={htmlTitle}
      description={description}
      pageType="blog-post"
      heroImage={heroImage}
    >
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={twitterID} />
        <meta property="og:url" content={nowUrl} />
        <meta property="og:title" content={title} />
        {description && (
          <meta property="og:description" content={description} />
        )}
        {heroImage && (
          <meta property="og:image" content={siteUrl + heroImage.publicURL} />
        )}
      </Helmet>
      <h1 className="blog-title mb-2">{title}</h1>
      {/* time category */}
      <div className="mb-5 flex justify-between">
        {/* category 存在するなら出力 */}
        {category && (
          <div>
            <Link
              to={`/category/${category}`}
              className="flex items-center hover:text-blue-300"
            >
              <span className="material-icons">folder_open</span>
              {category}
            </Link>
          </div>
        )}
        {/* time 存在するなら出力 */}
        {date && (
          <div className="flex items-center">
            <span className="material-icons">today</span>
            <time dateTime={date} className="">
              {date}
            </time>
          </div>
        )}
      </div>
      <BigBanner className="mb-5" />
      <div className="blog-content mb-5">
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{blogData.body}</MDXRenderer>
        </MDXProvider>
      </div>
      <BigBanner className="mb-5" />
      {/* tag 存在するなら出力*/}
      {Array.isArray(tags) && (
        <div className="mb-5">
          <div className="text-2xl font-bold">この記事のタグ</div>
          <div>
            {tags.map((tag, i) => (
              <Link
                key={i}
                to={`/tag/${tag}`}
                className="inline-block mt-1 mr-1 py-1 px-4 border-solid border-2 border-blue-300
                             rounded-full hover:bg-blue-300 hover:text-white"
              >
                {tag}
              </Link>
            ))}
          </div>
        </div>
      )}
      {/* snsシェア */}
      <div className="text-center text-2xl font-bold">Share</div>
      <div className="flex justify-evenly">
        {/* Facebookボタン */}
        <div className="facebook">
          <a
            href={`//www.facebook.com/sharer.php?src=bm&u=${encodeURI(
              nowUrl
            )}&t=${encodeURI(htmlTitle)}`}
            target="_blank"
            rel="noreferrer"
            className="bg-facebook w-12 h-12 rounded-full flex items-center justify-center"
          >
            {/* svgはStaticImageで使えない */}
            <img src="/svg/facebook_white.svg" className="w-8" />
          </a>
        </div>
        {/* ツイートボタン */}
        <div className="twitter">
          <a
            href={`//twitter.com/intent/tweet?url=${encodeURI(
              nowUrl
            )}&text=${encodeURI(htmlTitle)}&tw_p=tweetbutton`}
            target="_blank"
            rel="noreferrer"
            className="bg-twitter w-12 h-12 rounded-full flex items-center justify-center"
          >
            <img src="/svg/twitter_white.svg" className="w-8" />
          </a>
        </div>
        {/* ラインボタン */}
        <div className="line">
          <a
            className="line icon-line bg-line w-12 h-12 rounded-full flex items-center justify-center"
            href={`//timeline.line.me/social-plugin/share?url=${encodeURI(
              nowUrl
            )}`}
            target="_blank"
            rel="noreferrer"
            title="LINEでシェアする"
          >
            <img src="/svg/line_white.svg" className="w-8" />
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default BlogPostTemplate;

export const blogPostBySlugQuery = graphql`
  query blogPostBySlugQuery($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        twitterID
      }
    }
    mdx(slug: { eq: $slug }) {
      slug
      body
      frontmatter {
        title
        description
        date(formatString: "YYYY-MM-DD HH:mm")
        category
        tags
        heroImage {
          childImageSharp {
            gatsbyImageData(width: 1024)
          }
          publicURL
        }
      }
    }
  }
`;
